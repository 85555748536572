import { gql } from '@apollo/client';

const GET_SYNCS = gql`
  query ($accountId: String!) {
    syncs(accountId: $accountId) {
      id
      version
      accountId
      dataWarehouseId
      name
      isEnabled
      specification {
        audienceId
        destination
        segmentation
        executionTrigger {
          ... on RealTimeSyncExecutionTriggerSpecification {
            type
          }
          ... on ManualSyncExecutionTriggerSpecification {
            type
          }
          ... on ScheduledSyncExecutionTriggerSpecification {
            type
            schedule {
              type
              value
            }
            recordsScope {
              ... on AllSyncRecordsScope {
                type
              }

              ... on UnprocessedSyncRecordsScope {
                type
              }

              ... on SyncRecordsInTimePeriodScope {
                type
                filter {
                  ... on SyncCreatedRecordsScopeFilter {
                    type
                    createdAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }

                  ... on SyncUpdatedRecordsScopeFilter {
                    type
                    updatedAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }

                  ... on SyncCreatedOrUpdatedRecordsScopeFilter {
                    type
                    createdAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                    updatedAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      createdAt
      updatedAt
      deployment {
        status
        commitError
        rollBackError
        executionError
      }
      lastExecution {
        execution {
          id
          createdAt
          startedAt
          endedAt
          status
          abortedBy
        }
      }
    }
  }
`;
const GET_SYNCS_TABLE_LIST = gql`
  query ($accountId: String!) {
    syncs(accountId: $accountId) {
      id
      version
      accountId
      dataWarehouseId
      name
      isEnabled
      specification {
        audienceId
        destination
        segmentation
        executionTrigger {
          ... on RealTimeSyncExecutionTriggerSpecification {
            type
          }
          ... on ManualSyncExecutionTriggerSpecification {
            type
          }
          ... on ScheduledSyncExecutionTriggerSpecification {
            type
            schedule {
              type
              value
            }
            recordsScope {
              ... on AllSyncRecordsScope {
                type
              }

              ... on UnprocessedSyncRecordsScope {
                type
              }

              ... on SyncRecordsInTimePeriodScope {
                type
                filter {
                  ... on SyncCreatedRecordsScopeFilter {
                    type
                    createdAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }

                  ... on SyncUpdatedRecordsScopeFilter {
                    type
                    updatedAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }

                  ... on SyncCreatedOrUpdatedRecordsScopeFilter {
                    type
                    createdAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                    updatedAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      createdAt
      updatedAt
      deployment {
        status
        commitError
        rollBackError
        executionError
      }
      lastExecution {
        execution {
          id
          createdAt
          startedAt
          endedAt
          status
          abortedBy
        }
      }
    }
    audiences(accountId: $accountId) {
      id
      name
    }
    connections(accountId: $accountId) {
      __typename
      id
      name
      type
      settings
    }
  }
`;
const GET_SYNC = gql`
  query ($id: String!) {
    sync(id: $id) {
      id
      version
      accountId
      dataWarehouseId
      name
      isEnabled
      specification {
        audienceId
        destination
        segmentation
        executionTrigger {
          ... on RealTimeSyncExecutionTriggerSpecification {
            type
          }
          ... on ManualSyncExecutionTriggerSpecification {
            type
          }
          ... on ScheduledSyncExecutionTriggerSpecification {
            type
            schedule {
              type
              value
            }
            recordsScope {
              ... on AllSyncRecordsScope {
                type
              }

              ... on UnprocessedSyncRecordsScope {
                type
              }

              ... on SyncRecordsInTimePeriodScope {
                type
                filter {
                  ... on SyncCreatedRecordsScopeFilter {
                    type
                    createdAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }

                  ... on SyncUpdatedRecordsScopeFilter {
                    type
                    updatedAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }

                  ... on SyncCreatedOrUpdatedRecordsScopeFilter {
                    type
                    createdAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                    updatedAt {
                      ... on RecordsScopeBeforeDateFilter {
                        type
                        before
                      }

                      ... on RecordsScopeAfterDateFilter {
                        type
                        after
                      }

                      ... on DateRangeRecordsScopeFilter {
                        type
                        after
                        before
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      createdAt
      updatedAt
      deployment {
        status
        commitError
        rollBackError
        executionError
      }
      lastExecution {
        execution {
          id
          createdAt
          startedAt
          endedAt
          status
          abortedBy
        }
      }
    }
  }
`;
const GET_DEPLOYED_SYNC = gql`
  query ($syncId: String!) {
    deployedSync(id: $syncId) {
      id
      name
      destination
      audienceId
      dataPlane
    }
  }
`;
const GET_DESTINATION_ENTITIES = gql`
  query ($accountId: String!, $settings: JSON!) {
    getDestinationEntities(accountId: $accountId, settings: $settings) {
      __typename
      ... on DatabaseDestinationEntities {
        type
        entities {
          type
          key
          name
          meta
          tables {
            type
            key
            name
            meta
          }
        }
      }

      ... on FileSystemDestinationEntities {
        type
        entities {
          ... on DestinationFolderEntity {
            name
            key
            type
            children {
              ... on DestinationFileEntity {
                name
                key
                type
              }
              ... on DestinationFolderEntity {
                name
                key
                type
                children {
                  ... on DestinationFileEntity {
                    name
                    key
                    type
                  }
                }
              }
            }
          }
          ... on DestinationFileEntity {
            name
            key
            type
          }
        }
      }
    }
  }
`;
const GET_DESTINATION_SCHEMA = gql`
  query ($query: GetDestinationSchemaQuery!) {
    getDestinationSchema(query: $query) {
      __typename
      ... on DatabaseDestinationSchema {
        __typename
        isTableFound
        columnDescriptors {
          name
        }
      }
      ... on GetDestinationSchemaConnectionNotFound {
        __typename
        id
      }

      ... on GetDestinationSchemaConnectionDestinationTypeIsNotSupported {
        __typename
        connectionId
        destinationType
      }
    }
  }
`;
const GET_DESTINATION_TRIGGERS = gql`
  query ($query: GetDestinationTriggersQuery!) {
    getDestinationTriggers(query: $query) {
      __typename
      ... on GetDestinationTriggersConnectorTypeIsNotSupported {
        connectionId
        connectorType
      }

      ... on GetDestinationTriggersConnectionNotFound {
        id
      }

      ... on DestinationTriggers {
        triggers
      }
    }
  }
`;

const GET_API_DESTINATION_SCHEMA = gql`
  query ($query: GetApiConnectionEntitySchemaQuery!) {
    getApiConnectionEntityDestinationSchema(query: $query) {
      __typename
      ... on ApiConnectionEntityDestinationSchema {
        __typename
        id
        label
        fields {
          id
          label
          type
          canBeKey
          isUnique
          isOutput
          isRequired
        }
      }
      ... on ConnectionNotFound {
        __typename
        id
      }
      ... on EntityTypeNotFound {
        __typename
        connectionId
        connectionType
        entityTypeId
      }
    }
  }
`;
export {
  GET_SYNCS,
  GET_SYNCS_TABLE_LIST,
  GET_SYNC,
  GET_DEPLOYED_SYNC,
  GET_DESTINATION_ENTITIES,
  GET_DESTINATION_SCHEMA,
  GET_DESTINATION_TRIGGERS,
  GET_API_DESTINATION_SCHEMA,
};
