const monitoring = {
  executionsMonitoringStatus: 'Status',
  executionsMonitoringCreatedAt: 'Created at',
  executionsMonitoringResources: 'Resources',
  executionsMonitoringExecutionId: 'Execution ID',
  executionsMonitoringCreationDate: 'Creation date',
  executionsMonitoringEndDate: 'End date',
  completionRate: 'Completion rate',
  batchSize: 'Batch size',

  executionDetails: 'Execution Details',
  listOfActions: 'List of actions',

  actionResource: 'Resource',
  actionStartDate: 'Start Date',
  actionEndDate: 'End Date',
  actionStatus: 'Status',
  actionType: 'Action',
  actionOptions: 'Options',

  executionStatusNothingToDo: 'Nothing To Do',
  executionStatusPending: 'Pending',
  executionStatusInProgress: 'In Progress',
  executionStatusCompleted: 'Completed',
  executionStatusFailed: 'Failed',

  actionStatusPending: 'Pending',
  actionStatusSkipped: 'Skipped',
  actionStatusInProgress: 'In Progress',
  actionStatusFailed: 'Failed',
  actionStatusCompleted: 'Completed',

  actionCopyResourceId: 'Copy {{resource}} ID',
  actionSeeLogs: 'See logs',
  actionLogsTitle: 'Action logs',

  sourceActionInitializeSource: 'Initialize source',
  sourceActionFetchNewSourceData: 'Fetch new source data',
  sourceActionComputeSource: 'Compute source',

  datasetActionInitializeDataset: 'Initialize dataset',
  datasetActionIntegrateSourceDataToDataset: 'Integrate source data to dataset',
  datasetActionComputeDataset: 'Compute dataset',

  syncActionDeliverAllSyncRecords: 'Deliver all sync records',
  syncActionDeliverSyncRecordsIncrement: 'Deliver sync records increment',
  syncActionDeliverSpecificSyncRecords: 'Deliver specific sync records',

  analyticsSyncActionDeliverDatasetRecordsToAnalytics: 'Deliver dataset records to analytics',
  garbageCollectionActionCleanUpDataWarehouseGarbage: 'Clean up data warehouse garbage',
  garbageCollectionActionCleanUpExecutionsGarbage: 'Clean up executions garbage',

  accountResourcesGarbageCollectionResourceName: 'Garbage Collection',
  resources: 'Resources',
};

export default monitoring;
