import { GraphQLError } from 'graphql';

export const getApiErrorMessage = (error: unknown): string => {
  let errorMessage: string = '';
  if (Array.isArray(error) && error[0] instanceof GraphQLError) {
    // Handle array of GraphQL errors
    errorMessage = error.map((graphQLError) => graphQLError.message).join(' ');
  } else if (error instanceof GraphQLError) {
    errorMessage = error.message;
  } else if (error instanceof Error) {
    errorMessage = error.message;
  } else if (error instanceof Object) {
    errorMessage = JSON.stringify(error, Object.getOwnPropertyNames(error));
  } else {
    return 'Unexpected error type';
  }
  return errorMessage;
};
