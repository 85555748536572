import mappingHelpers from '@features/audiences/helpers/mappingSourcesHelpers/mappingHelpers';
import {
  ActiveSourceConfiguration,
  columnDisplayEnum,
  MappedColumn,
  MappedColumns,
  MappingRow,
  mappingRowTypesEnum,
} from '@features/audiences/types';
import { columnTypesEnum } from '@features/objects/types';
import {
  AggregatedAudienceColumn,
  JoinedAudienceSource,
} from '../../../audienceBackTypes/audienceSpecification';
import { DataSourceType } from '../../../audienceBackTypes/source';
import mappingAggregateProperty from './mappingAggregateProperty';
import getSourceIdFromSourceConfig from '../../helpers/getSourceIdFromSourceConfig';

export const mappingAggregatedColumns = (
  joinedSources: JoinedAudienceSource[],
  sourcesConfigurationList: ActiveSourceConfiguration[],
  audienceMapping: MappingRow[]
): MappingRow[] => {
  let joinAggsMappingRows: MappingRow[] = [];
  joinedSources.forEach((joinedSource) => {
    const aggMappingRows: MappingRow[] = joinedSource.aggregatedColumns.reduce(
      (result: MappingRow[], aggsColumn) => {
        let mappingRow: MappingRow = {
          key: aggsColumn.targetColumnId,
          audienceColumnName: aggsColumn.targetColumnName,
          audienceColumnType: columnTypesEnum.STRING, // to change
          mappedColumns: {},
          rowType: mappingRowTypesEnum.AGGREGATE_ROW,
          canDelete: true,
        };
        const combinationLevel: number | undefined = sourcesConfigurationList.find(
          (x) => x.key === getSourceIdFromSourceConfig(joinedSource)
        )?.combinationLevel;
        const hiddenColumns: MappedColumns = combinationLevel
          ? mappingHelpers.hideMappedColumnsHandler(sourcesConfigurationList, combinationLevel)
          : {};
        switch (joinedSource.source.type) {
          case DataSourceType.connection:
            mappingRow = {
              ...mappingRow,
              mappedColumns: {
                ...hiddenColumns,
                [joinedSource.source.id]: mappingMappedColumnDetails(aggsColumn),
              },
            };

            break;
          case DataSourceType.audience:
            mappingRow = {
              ...mappingRow,
              mappedColumns: {
                ...hiddenColumns,
                [joinedSource.source.audienceId]: mappingMappedColumnDetails(aggsColumn),
              },
            };
            break;

          default:
            break;
        }
        result.push(mappingRow);
        return result;
      },
      []
    );
    joinAggsMappingRows = [...joinAggsMappingRows, ...aggMappingRows];
  });

  return [...audienceMapping, ...joinAggsMappingRows];
};

const mappingMappedColumnDetails = (
  aggregatedAudienceColumn: AggregatedAudienceColumn
): MappedColumn => {
  return {
    columnId: aggregatedAudienceColumn.targetColumnId,
    columnName: aggregatedAudienceColumn.targetColumnName,
    columnType: columnTypesEnum.STRING,
    aggregate: mappingAggregateProperty(aggregatedAudienceColumn.aggregation),
    columnDisplay: columnDisplayEnum.DISABLED,
  };
};
