import {
  JoinedAudienceSource,
  UnionedAudienceSource,
} from '../../audienceBackTypes/audienceSpecification';
import { DataSourceType } from '../../audienceBackTypes/source';

const getSourceIdFromSourceConfig = (
  sourceConfig: UnionedAudienceSource | JoinedAudienceSource
) => {
  switch (sourceConfig.source.type) {
    case DataSourceType.audience:
      return sourceConfig.source.audienceId;
    case DataSourceType.sync:
      return sourceConfig.source.syncId;
    case DataSourceType.connection:
      return sourceConfig.source.id;
  }
};

export default getSourceIdFromSourceConfig;
