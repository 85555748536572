import { ConnectorType } from '@features/connections/types';
import { IColumnType } from './columnType';
import { BackSourcePreparation } from './backColumnPreparationStep';
import { sftpModifiedAtColumnSource, SftpSourceType } from '@features/audiences/types';
import { ConnectionDataSourceModifiedAtOptions } from './ConnectionDataSourceModifiedAtOptions';
import { IDecryption } from './decryption';
import { Schedule } from '@contracts/schedule';
import { BusinessCategoryEnum } from '@features/objects/types';
import { IAudienceSourceExecutionTriggerSpecification } from './AudienceSourceExecutionTrigger';
import { IBigQuerySourceFilteringCriteria, IMySQLSourceSettings, ISourceSettingsV2 } from '../types';

export enum DataSourceType {
  audience = 'audience',
  dataset = 'dataset',
  connection = 'connection',
  sync = 'sync',
}
// TODO @@@@koralex name? // TODO @@@@slava rename to AudienceSource and ConnectionSource
export type DataSource = AudienceDataSource | ConnectionDataSource | SyncDataSource;

// TODO @@@@slava leads to the cycles, infinite loop issue could be here
export type AudienceDataSource = Readonly<{
  type: DataSourceType.audience;
  audienceId: string;
  executionTrigger: IAudienceSourceExecutionTriggerSpecification;
  draftSourceId?: string;
}>;

export type ConnectionDataSource<SourceSettings extends ISourceSettings = ISourceSettings> =
  Readonly<{
    type: DataSourceType.connection;

    id: string;
    name: string;
    // TODO @@@@koralex source's state (probably) requires created_at column

    // Source columns with Modified-At specified explicitly
    columns: SourceColumn[];
    // Modified-At column, its format and extra handling optoins
    modifiedAtColumnId: SourceColumn['id'];
    modifiedAtFormat?: string;
    modifiedAtOptions?: ConnectionDataSourceModifiedAtOptions;

    connectionId: string;
    sourceSettings: SourceSettings;
    businessCategory: BusinessCategoryEnum;

    deduplicationSettings: SourceDeduplicationSettings;

    schedule: Schedule;

    preparations: BackSourcePreparation[];

    draftSourceId?: string;
  }>;
export type SyncDataSource = {
  type: DataSourceType.sync;
  syncId: string;
  masterIdColumnId: string;
  modifiedAtColumnId: string;
  columns: SyncDataSourceColumn[];
  draftSourceId?: string;
};

// Source Column

export type SourceColumnId = string;

export type SourceColumnName = string;

export type SourceColumnType = IColumnType;

export type SourceColumnExternalName = string;

export type BaseSourceColumn = Readonly<{
  id: SourceColumnId;
  name: SourceColumnName;
  type: SourceColumnType;

  castTo?: SourceColumnType;
}>;
export enum SourceColumnRetrievalType {
  static = 'static',
  value = 'value',
}

export type StaticSourceColumn = BaseSourceColumn &
  Readonly<{
    retrievalType: SourceColumnRetrievalType.static;
    value: any;
  }>;

export type ValueSourceColumn = BaseSourceColumn &
  Readonly<{
    retrievalType: SourceColumnRetrievalType.value;
    externalName?: SourceColumnExternalName;
  }>;

export type SourceColumn = StaticSourceColumn | ValueSourceColumn;

export type SyncDataSourceColumn = BaseSourceColumn & {
  fieldId: string;
};

export type SourceDeduplicationSettings = Readonly<{
  // TODO @@@@koralex should it be array of arrays? or a single one?
  key: SourceDeduplicationKey;
}>;

export type SourceDeduplicationKey = Readonly<{
  columnIds: SourceColumn['id'][];
}>;

// source settings
export type ISourceSettings =
  | IBigQuerySourceSettings
  | IPostgresSourceSettings
  | IMySQLSourceSettings
  | ISftpSourceSettings
  | ISnowflakeSourceSettings
  | ISourceSettingsV2;

type IBigQuerySourceSettings = Readonly<{
  type: ConnectorType.bigQuery;
  datasetId: string;
  tableId: string;
  location: string;
  fullModeFilter: IBigQuerySourceFilteringCriteria;
  incrementalModeFilter: IBigQuerySourceFilteringCriteria;
}>;
type IPostgresSourceSettings = Readonly<{
  type: ConnectorType.postgres;
  // TODO @@@@koralex
  // pointer: {
  //   name: 'contacts_1_external',
  //   schemaName: 'shop_1',
  //   type: 'table',
  // },
  schemaName: string;
  tableName: string;
}>;

export enum FileType {
  csv = 'csv',
  json = 'json',
}

type CsvFileProcessingSettings = {
  type: FileType.csv;
  delimiter: string;
  escape: string;
  quote: string;
  bom?: boolean;
};

type JsonFileProcessingSettings = {
  type: FileType.json;
  pattern: string | null;
};

export type FileProcessingSettings = CsvFileProcessingSettings | JsonFileProcessingSettings;

type ISftpSourceSettings = Readonly<{
  type: ConnectorType.sftp;
  directoryPath: string;

  sourceType: SftpSourceType;

  processing: FileProcessingSettings;
  decryption: IDecryption;

  filesMatchPattern: string;
  filesMatchPatternOptions: string;

  ignoredDirectories: string[];

  modifiedAtColumn?:
    | {
        type: sftpModifiedAtColumnSource.fileName;
        regex: string;
        columnName: string;
      }
    | {
        type: sftpModifiedAtColumnSource.source;
      };
}>;
type ISnowflakeSourceSettings = Readonly<{
  type: ConnectorType.snowflake;
  schemaName: string;
  tableName: string;
}>;
