import { call, put, select, take } from 'redux-saga/effects';
// api
import { getAudienceData } from '@features/audiences/ducks/api/api';
import { GetAudienceDataSaga } from '@features/audiences/ducks/types';
import { AudienceData, DeployedAudience } from '@features/audiences/types/DeployedAudience';
import { Connection } from '@features/connections/types';
import { redirectToSyncsSaga } from '@features/redirections/ducks/sagas';
import {
  activeAccountIdStateSelector,
  activeDataWarehouseIdStateSelector,
  connectionsSelector,
  deployedAudiencesSelector,
} from '@redux/selectors';
import {
  GetDestinationEntitiesInput,
  GetDestinationEntitiesInputTypes,
  GetDestinationEntitiesOutput,
  GetDestinationEntitiesOutputTypes,
} from '../../types/DestinationEntity';
import { DestinationSchema } from '../../types/DestinationSchema';
import { GetDestinationTriggersInput } from '../../types/DestinationTriggers';
import * as API from '../api/api';
import { entityToDestinationSchemaSettings } from '../api/mappingSyncTypes/entityToDestinationSchemaSettings';
import syncsSlice from '../syncsSlice';
import * as types from '../types';
import {
  ReducerAlterDatabaseSyncTableIActions,
  ReducerCreateDatabaseSyncTableIActions,
  ReducerPerformDatabaseSyncTableOperationsIActions,
  ReducerValidateDatabaseSyncTableIActions,
} from '../types';

import {
  AlterDatabaseSyncTableInput,
  CreateDatabaseSyncTableInput,
  ValidateDatabaseSyncTableInput,
} from '../../types/DatabaseSyncTableCommandsInputs';
import { getApiErrorMessage } from '@utils/api-error-handler';
import { DestinationEnum } from '../../types/Destination';
import * as connectionsApi from '@features/connections/ducks/api/api';
import {
  ApiConnectionEntityTypeOutput,
  ListApiConnectionEntityTypesInput,
} from '@features/connections/types/ApiConnectionEntity';
import { ReducerStates } from '@redux/reducers';

export function* getSyncsSaga({ payload }: types.GetSyncsSaga) {
  const { abortSignal } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const getSyncsPayload: types.ApiSyncsPayload = yield call(API.getSyncs, accountId, abortSignal);

    yield put({
      type: syncsSlice.actions.getSyncsSuccess.type,
      payload: { ...getSyncsPayload },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: syncsSlice.actions.getSyncsFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}

export function* getSyncsTableListSaga({ payload }: types.GetSyncsTableListSaga) {
  const { abortSignal } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const getSyncsTableListPayload: types.ApiGetSyncsTableListPayload = yield call(
      API.getSyncsTableList,
      accountId,
      abortSignal
    );

    yield put({
      type: syncsSlice.actions.getSyncsTableListSuccess.type,
      payload: getSyncsTableListPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: syncsSlice.actions.getSyncsTableListFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}

export function* saveSyncSaga({ payload }: types.SaveSyncSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);
  const connections: Connection[] = yield select(connectionsSelector);
  const deployedAudiences: DeployedAudience[] = yield select(deployedAudiencesSelector);
  try {
    const { activeSync } = payload;
    const connectionsDetails = connections.reduce(
      (result: { [conId: string]: Connection }, elem) => {
        return { ...result, [elem.id]: elem };
      },
      {}
    );
    const deployedAudiencesDetails = deployedAudiences.reduce(
      (result: { [audId: string]: DeployedAudience }, elem) => {
        return { ...result, [elem.id]: elem };
      },
      {}
    );

    const saveSyncPayload: types.APISyncPayload = yield call(
      API.saveSync,
      activeSync,
      accountId,
      dataWarehouseId
    );
    yield put({
      type: syncsSlice.actions.saveSyncSuccess.type,
      payload: { ...saveSyncPayload, connectionsDetails, deployedAudiencesDetails },
    });
    yield call(redirectToSyncsSaga);
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.log(err);
      yield put({
        type: syncsSlice.actions.saveSyncFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}

export function* updateSyncSaga({ payload }: types.SaveSyncSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);
  const dataWarehouseId: string = yield select(activeDataWarehouseIdStateSelector);
  const connections: Connection[] = yield select(connectionsSelector);
  const deployedAudiences: DeployedAudience[] = yield select(deployedAudiencesSelector);
  try {
    const { activeSync } = payload;
    const connectionsDetails = connections.reduce(
      (result: { [conId: string]: Connection }, elem) => {
        return { ...result, [elem.id]: elem };
      },
      {}
    );
    const deployedAudiencesDetails = deployedAudiences.reduce(
      (result: { [audId: string]: DeployedAudience }, elem) => {
        return { ...result, [elem.id]: elem };
      },
      {}
    );

    const updateSyncPayload: types.APISyncPayload = yield call(
      API.updateSync,
      activeSync,
      accountId,
      dataWarehouseId
    );
    yield put({
      type: syncsSlice.actions.updateSyncSuccess.type,
      payload: { ...updateSyncPayload, connectionsDetails, deployedAudiencesDetails },
    });
    yield call(redirectToSyncsSaga);
  } catch (err: unknown) {
    if (err instanceof Error) {
      console.log(err);
      yield put({
        type: syncsSlice.actions.updateSyncFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}

export function* deleteSyncSaga({ payload }: types.DeleteSyncSaga) {
  const { id } = payload;
  try {
    const deleteSyncPayload: types.APIDeleteSyncPayload = yield call(API.deleteSync, id);
    yield put({
      type: syncsSlice.actions.deleteSyncSuccess.type,
      payload: deleteSyncPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: syncsSlice.actions.deleteSyncFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

export function* enableSyncSaga({ payload }: types.EnableSyncSaga) {
  const { id } = payload;
  try {
    const enableSyncPayload: types.APIActivationSyncPayload = yield call(API.enableSync, id);
    yield put({
      type: syncsSlice.actions.enableSyncSuccess.type,
      payload: enableSyncPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: syncsSlice.actions.enableSyncFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

export function* disableSyncSaga({ payload }: types.DisableSyncSaga) {
  const { id } = payload;
  try {
    const disableSyncPayload: types.APIActivationSyncPayload = yield call(API.disableSync, id);
    yield put({
      type: syncsSlice.actions.disableSyncSuccess.type,
      payload: disableSyncPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: syncsSlice.actions.disableSyncFailed.type,
        payload: { error: err, errorDetails: err.message },
      });
    }
  }
}

export function* requestSyncExecutionSaga({ payload }: types.RequestSyncExecutionSaga) {
  const { syncExecutionValue } = payload;
  try {
    const executeSyncPayload: types.APIActivationSyncPayload = yield call(
      API.requestSyncExecution,
      syncExecutionValue
    );

    if (executeSyncPayload.error) {
      yield put({
        type: syncsSlice.actions.requestSyncExecutionFailed.type,
        payload: {
          error: executeSyncPayload.error,
          errorDetails: executeSyncPayload.errorDetails,
          syncId: syncExecutionValue.syncId,
        },
      });
    } else {
      yield put({
        type: syncsSlice.actions.requestSyncExecutionSuccess.type,
        payload: executeSyncPayload,
      });
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: syncsSlice.actions.requestSyncExecutionFailed.type,
        payload: { error: err, errorDetails: err.message, syncId: syncExecutionValue.syncId },
      });
    }
  }
}

export function* getDestinationSchemaSaga({
  payload: { destination },
}: types.GetDestinationSchemaSaga) {
  const accountId: string = yield select(activeAccountIdStateSelector);

  try {
    if (destination.type === DestinationEnum.api) {
      const apiDestinationSchemaResult: DestinationSchema = yield call(
        API.getApiDestinationSchema,
        {
          connectionId: destination.connectionId,
          entityTypeId: destination.settings.entityTypeId,
          entitySettings: destination.settings.entitySettings,
        }
      );
      yield put({
        type: syncsSlice.actions.getDestinationSchemaSuccess.type,
        payload: { destinationSchema: apiDestinationSchemaResult },
      });
    } else {
      const destinationSchemaResult: DestinationSchema = yield call(API.getDestinationSchema, {
        accountId,
        connectionId: destination.connectionId,
        destinationSchemaSettings: entityToDestinationSchemaSettings(destination),
      });
      yield put({
        type: syncsSlice.actions.getDestinationSchemaSuccess.type,
        payload: { destinationSchema: destinationSchemaResult },
      });
    }
  } catch (err: unknown) {
    const errorDetails = getApiErrorMessage(err);
    yield put({
      type: syncsSlice.actions.getDestinationSchemaFailed.type,
      payload: { errorDetails },
    });
  }
}

export function* getDestinationEntitiesSaga({ payload }: types.GetDestinationEntitiesSaga) {
  const { connectionId, destinationType } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  let destinationEntities: GetDestinationEntitiesOutput;
  try {
    if (destinationType === DestinationEnum.api) {
      const apiDestinationEntitiesInput: ListApiConnectionEntityTypesInput = {
        connectionId,
      };
      const getApiDestinationEntitiesPayload: ApiConnectionEntityTypeOutput = yield call(
        connectionsApi.getApiConnectionEntityTypes,
        apiDestinationEntitiesInput
      );
      destinationEntities = {
        type: GetDestinationEntitiesOutputTypes.api,
        ...getApiDestinationEntitiesPayload,
      };
    } else {
      const destinationEntitiesInput: GetDestinationEntitiesInput = {
        accountId,
        settings: { connectionId, type: GetDestinationEntitiesInputTypes.connection },
      };
      const getDestinationEntitiesPayload: GetDestinationEntitiesOutput = yield call(
        API.getDestinationEntities,
        destinationEntitiesInput
      );
      destinationEntities = getDestinationEntitiesPayload;
    }
    yield put({
      type: syncsSlice.actions.getDestinationEntitiesSuccess.type,
      payload: { destinationEntities },
    });
  } catch (err: unknown) {
    const errorDetails = getApiErrorMessage(err);
    yield put({
      type: syncsSlice.actions.getDestinationEntitiesFailed.type,
      payload: { err, errorDetails: errorDetails },
    });
  }
}

export function* getDestinationTriggersSaga({ payload }: types.GetDestinationTriggersSaga) {
  const { connectionId } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const getDestinationTriggersInput: GetDestinationTriggersInput = {
      query: {
        accountId,
        connectionId,
      },
    };
    const getDestinationTriggersPayload: types.GetDestinationTriggersPayload = yield call(
      API.getDestinationTriggers,
      getDestinationTriggersInput
    );
    yield put({
      type: syncsSlice.actions.getDestinationTriggersSuccess.type,
      payload: getDestinationTriggersPayload,
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: syncsSlice.actions.getDestinationTriggersFailed.type,
        payload: { err, errorDetails: err.message },
      });
    }
  }
}

export function* getAudienceDataForSyncSaga({ payload }: GetAudienceDataSaga) {
  try {
    const audienceDataResult: AudienceData = yield call(
      getAudienceData,
      payload.audienceId,
      payload.paging,
      payload.search,
      payload.abortSignal
    );
    yield put({
      type: syncsSlice.actions.getAudienceDataForSyncSuccess.type,
      payload: { audienceData: audienceDataResult },
    });
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put({
        type: syncsSlice.actions.getAudienceDataForSyncFailed.type,
        payload: { errorDetails: err.message },
      });
    }
  }
}

export function* getSyncToEditSaga({ syncId }: types.GetSyncToEditSaga) {
  try {
    if (syncId) {
      const getSyncDataPayload: types.APISyncPayload = yield call(API.getSync, syncId);
      return { sync: getSyncDataPayload.sync };
    }
    const errorDetails = 'Param sync id is not found';
    return { errorDetails, error: true };
  } catch (err: unknown) {
    if (err instanceof Error) {
      const errorDetails = err.message;
      return { errorDetails, error: true };
    }
  }
}

export function* createDatabaseSyncTableSaga({ payload }: types.CreateDatabaseSyncTableSaga) {
  const { connectionId, audienceId, destinationSettings } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const createDatabaseSyncTableInput: CreateDatabaseSyncTableInput = {
      command: {
        accountId,
        connectionId,
        audienceId,
        destinationSettings,
      },
    };
    const createDatabaseSyncTablePayload: types.CreateDatabaseSyncTablePayload = yield call(
      API.createDatabaseSyncTable,
      createDatabaseSyncTableInput
    );
    if (createDatabaseSyncTablePayload.errors.length) {
      const err = createDatabaseSyncTablePayload.errors[0];
      const errorPayload = {
        errorDetails: JSON.stringify(err),
        success: false,
        loaded: true,
        errors: [err],
        isFetching: false,
      };
      yield put<ReducerCreateDatabaseSyncTableIActions>({
        type: syncsSlice.actions.createDatabaseSyncTableFailed.type,
        payload: errorPayload,
      });

      return errorPayload;
    } else {
      yield put<ReducerCreateDatabaseSyncTableIActions>({
        type: syncsSlice.actions.createDatabaseSyncTableSuccess.type,
        payload: createDatabaseSyncTablePayload,
      });

      return createDatabaseSyncTablePayload;
    }
  } catch (err: unknown) {
    if (err instanceof Error) {
      const resultPayload = {
        errorDetails: err.message,
        errors: [err],
        loaded: true,
        success: false,
        isFetching: false,
      };
      yield put<ReducerCreateDatabaseSyncTableIActions>({
        type: syncsSlice.actions.createDatabaseSyncTableFailed.type,
        payload: resultPayload,
      });

      return resultPayload;
    }
  }
}

export function* alterDatabaseSyncTableSaga({ payload }: types.AlterDatabaseSyncTableSaga) {
  const { connectionId, audienceId, destinationSettings } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const alterDatabaseSyncTableInput: AlterDatabaseSyncTableInput = {
      command: {
        accountId,
        connectionId,
        audienceId,
        destinationSettings,
      },
    };
    const alterDatabaseSyncTablePayload: types.AlterDatabaseSyncTablePayload = yield call(
      API.alterDatabaseSyncTable,
      alterDatabaseSyncTableInput
    );

    yield put<ReducerAlterDatabaseSyncTableIActions>({
      type: !alterDatabaseSyncTablePayload.success
        ? syncsSlice.actions.alterDatabaseSyncTableFailed.type
        : syncsSlice.actions.alterDatabaseSyncTableSuccess.type,
      payload: alterDatabaseSyncTablePayload,
    });

    return alterDatabaseSyncTablePayload;
  } catch (err: unknown) {
    if (err instanceof Error) {
      yield put<ReducerAlterDatabaseSyncTableIActions>({
        type: syncsSlice.actions.alterDatabaseSyncTableFailed.type,
        payload: {
          errorDetails: err.message,
          success: false,
          errors: [],
          loaded: true,
          isFetching: false,
          modified: false,
        },
      });
    }
  }
}

export function* validateDatabaseSyncTableSaga({ payload }: types.ValidateDatabaseSyncTableSaga) {
  const { connectionId, audienceId, destinationSettings } = payload;
  const accountId: string = yield select(activeAccountIdStateSelector);
  try {
    const commandInput: ValidateDatabaseSyncTableInput = {
      command: {
        accountId,
        connectionId,
        audienceId,
        destinationSettings,
      },
    };
    const result: types.ValidateDatabaseSyncTablePayload = yield call(
      API.validateDatabaseSyncTable,
      commandInput
    );

    const actionType =
      result.errorDetails || result.errors.length
        ? syncsSlice.actions.validateDatabaseSyncTableFailed.type
        : syncsSlice.actions.validateDatabaseSyncTableSuccess.type;
    yield put<ReducerValidateDatabaseSyncTableIActions>({
      type: actionType,
      payload: result,
    });

    return result;
  } catch (err: unknown) {
    yield put<ReducerValidateDatabaseSyncTableIActions>({
      type: syncsSlice.actions.validateDatabaseSyncTableFailed.type,
      payload: {
        errorDetails: 'Validation failed',
        isFetching: false,
        errors: [],
        success: false,
        loaded: true,
      },
    });
    console.error(err);
  }
}

export function* performDatabaseSyncTableOperationsSaga({
  payload,
}: types.PerformDatabaseSyncTableOperationsSaga) {
  const { connectionId, audienceId, destinationSettings, operation, operationConfirmed } = payload;

  const isTableValidated: boolean | undefined = yield select(
    (state: ReducerStates) => state.syncs.dbTableValidated
  );

  if (operation === 'create') {
    yield put({
      type: syncsSlice.actions.createDatabaseSyncTable.type,
      payload: {
        connectionId,
        audienceId,
        destinationSettings,
      },
    });
    const {
      payload: { errorDetails },
    } = yield take([
      syncsSlice.actions.createDatabaseSyncTableSuccess.type,
      syncsSlice.actions.createDatabaseSyncTableFailed.type,
    ]);

    if (errorDetails) {
      yield put<ReducerPerformDatabaseSyncTableOperationsIActions>({
        type: syncsSlice.actions.performDatabaseSyncTableOperationsFailed.type,
        payload: {
          errorDetails,
          loaded: true,
          isFetching: false,
          success: false,
          processFinished: false,
        },
      });
    } else {
      yield put<ReducerPerformDatabaseSyncTableOperationsIActions>({
        type: syncsSlice.actions.performDatabaseSyncTableOperationsSuccess.type,
        payload: {
          loaded: true,
          isFetching: false,
          success: true,
          processFinished: true,
        },
      });
    }

    return;
  }

  if (operation === 'alter') {
    if (!isTableValidated) {
      yield put({
        type: syncsSlice.actions.validateDatabaseSyncTable.type,
        payload: {
          connectionId,
          audienceId,
          destinationSettings,
        },
      });

      const {
        payload: { errors, error },
      } = yield take([
        syncsSlice.actions.validateDatabaseSyncTableSuccess.type,
        syncsSlice.actions.validateDatabaseSyncTableFailed.type,
      ]);

      if (error) {
        yield put<ReducerPerformDatabaseSyncTableOperationsIActions>({
          type: syncsSlice.actions.performDatabaseSyncTableOperationsFailed.type,
          payload: {
            errorDetails: error,
            loaded: true,
            isFetching: false,
            success: false,
            processFinished: false,
          },
        });
        return;
      }

      if (errors.length) {
        yield put<ReducerPerformDatabaseSyncTableOperationsIActions>({
          type: syncsSlice.actions.performDatabaseSyncTableOperationsSuccess.type,
          payload: {
            errorDetails: 'Validation failed',
            loaded: true,
            isFetching: false,
            success: true,
            processFinished: false,
            errors,
          },
        });
        return;
      }
    }

    const validationErrors: object[] | undefined = yield select(
      (state: ReducerStates) => state.syncs.dbTableValidationErrors
    );
    if (validationErrors?.length) {
      if (operationConfirmed) {
        yield put({
          type: syncsSlice.actions.alterDatabaseSyncTable.type,
          payload: {
            connectionId,
            audienceId,
            destinationSettings,
          },
        });

        const {
          payload: { errorDetails },
        } = yield take([
          syncsSlice.actions.alterDatabaseSyncTableSuccess.type,
          syncsSlice.actions.alterDatabaseSyncTableFailed.type,
        ]);

        if (errorDetails) {
          yield put<ReducerPerformDatabaseSyncTableOperationsIActions>({
            type: syncsSlice.actions.performDatabaseSyncTableOperationsFailed.type,
            payload: {
              errorDetails,
              loaded: true,
              isFetching: false,
              success: false,
              processFinished: false,
            },
          });
        } else {
          yield put<ReducerPerformDatabaseSyncTableOperationsIActions>({
            type: syncsSlice.actions.performDatabaseSyncTableOperationsSuccess.type,
            payload: {
              loaded: true,
              isFetching: false,
              success: true,
              processFinished: true,
            },
          });
          yield put({
            type: syncsSlice.actions.resetDatabaseTableValidationErrors.type,
          });
        }
      } else {
        yield put<ReducerPerformDatabaseSyncTableOperationsIActions>({
          type: syncsSlice.actions.performDatabaseSyncTableOperationsSuccess.type,
          payload: {
            loaded: true,
            isFetching: false,
            success: true,
            processFinished: true,
          },
        });
      }
      return;
    } else {
      yield put<ReducerPerformDatabaseSyncTableOperationsIActions>({
        type: syncsSlice.actions.performDatabaseSyncTableOperationsSuccess.type,
        payload: {
          loaded: true,
          isFetching: false,
          success: true,
          processFinished: true,
        },
      });
    }
  }
}

