import { columnTypesEnum } from '@features/objects/types';

export enum columnShapeTypeEnum {
  DEFAULT = 'default',
  STATIC = 'static',
}
export type StaticColumn = {
  id: string;
  shapeType: columnShapeTypeEnum.STATIC;
  name: string;
  type: columnTypesEnum;
  value: any;
  castTo?: columnTypesEnum;
  isNullable: boolean;
};
export type DefaultColumn = {
  id: string;
  shapeType: columnShapeTypeEnum.DEFAULT;
  name: string;
  externalName: string;
  type: columnTypesEnum;
  castTo?: columnTypesEnum;
  // Used for cast to timestamp type
  // @@@TODO Refactor this after aligning column types with backend type
  format?: string;
  isNullable: boolean;
};

export type Column = DefaultColumn | StaticColumn;

export type StaticColumnWithNoID = Omit<StaticColumn, 'id'>;
export type DefaultColumnWithNoID = Omit<DefaultColumn, 'id'>;
export type ColumnWithNoID = DefaultColumnWithNoID | StaticColumnWithNoID;
