import React, { FC } from 'react';
import { Button } from 'antd';
import styles from './index.module.css';

interface IErrorBoundaryFallback {
  error: any;
  resetErrorBoundary: (...args: any[]) => void;
}

export const ErrorBoundaryFallback: FC<IErrorBoundaryFallback> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <div className={styles.container}>
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>

      <Button type="text" className="octolis__primary-btn" onClick={resetErrorBoundary}>
        Try again
      </Button>
    </div>
  );
};
