import { ConnectorType } from '@features/connections/types';


export enum SftpDestinationDataSettingsEnum {
  csv = 'csv',
}

export type ISftpDestinationCsvDataSettings = {
  type: SftpDestinationDataSettingsEnum.csv;
  delimiter: string;
  escape: string;
  quote: string;
  bom?: boolean;
};
export type SftpDestinationColumn = {
  key: string;
  name: string;
  externalName?: string;
  sync: boolean;
};
export type ISFTPDestinationSettings = {
  type: ConnectorType.sftp;
  columns: SftpDestinationColumn[];
  directoryPath: string;
  fileName: string;
  fileNameExtension: string;
  dataSettings: ISftpDestinationCsvDataSettings;
};
export type FileSystemSettingsFormType = {
  destinationEntity: string;
  targetFileName: string;
  syncAllColumns: boolean;
  fsMapping?: FileSystemMappingFormType;
};
export type FileSystemMappingFormType = {
  [columnKey: string]: {
    audienceColumn: string;
    destinationColumnName: string;
    syncColumn: boolean;
  };
};

export enum DestinationReplacementMode {
  delete_records = 'delete_records',
  nullify_columns = 'nullify_columns',
}

export type IColumnName = string;
export type IPostgresDestinationSettings = {
  type: ConnectorType.postgres;
  schemaName: string;
  tableName: string;
  columns: IColumnName[];
  key: IColumnName[];
  replacementMode?: DestinationReplacementMode;
};
export type IBigQueryDestinationSettings = {
  type: ConnectorType.bigQuery;
  schemaName: string;
  tableName: string;
  location: string;
  columns: IColumnName[];
  key: IColumnName[];
  replacementMode?: DestinationReplacementMode;
};

export type IMySQLDestinationSettings = {
  type: ConnectorType.mysql;
  schemaName: string;
  tableName: string;
  columns: IColumnName[];
  key: IColumnName[];
  replacementMode?: DestinationReplacementMode;
};
export type IDatabaseDestinationSettings =
  | IPostgresDestinationSettings
  | IBigQueryDestinationSettings
  | IMySQLDestinationSettings;
export type IFileSystemDestinationSettings = ISFTPDestinationSettings;


