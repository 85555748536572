import {
  dataTypesEnum,
  JoinActiveSourceConfiguration,
  PreparationActionType,
  retrievalModeEnum,
  SourcePreparation,
} from '@features/audiences/types';
import { JoinedAudienceSource } from '../../audienceBackTypes/audienceSpecification';
import { DataSourceType } from '../../audienceBackTypes/source';
import { mappingJoinConditionProperty } from './mappingJoinConditionProperty';
import { mappingSelectedColumnsProperty } from './mappingSelectedColumnsProperty';
import { BackSourcePreparation } from '@features/audiences/ducks/api/audienceBackTypes/backColumnPreparationStep';
import { mappingColumnTypePropertyToFront } from './mappingColumnTypePropertyToFront';
import {
  mapSelectedEntity,
  mapSelectedEntityOfAudienceAsSource,
  mapSelectedEntityOfSyncAsSource
} from './mappingSelectedEntity';
import {
  mapBackendAudienceDatasourceToActiveDataSource,
  mapBackendConnectionDatasourceToActiveDataSource,
  mapBackendSyncDatasourceToActiveDataSource,
} from './mapDatasource';

// Mapping joined sources back type to sources configuration front type
export const mappingJoinedSources = (
  joinedSourcesBackType: JoinedAudienceSource[],
  startCombinationLevel: number
): JoinActiveSourceConfiguration[] => {
  const joinedSourcesConfiguration: JoinActiveSourceConfiguration[] = [];

  joinedSourcesBackType.forEach((backSourceConfig, index) => {
    if (backSourceConfig.source.type === DataSourceType.connection) {
      // Prepare dataSource and source configuration for connection source types
      const dataSource = mapBackendConnectionDatasourceToActiveDataSource(backSourceConfig.source);
      const sourceConfiguration: JoinActiveSourceConfiguration = {
        dataSource,
        dataType: dataTypesEnum.JOIN,
        key: backSourceConfig.source.id,
        combinationLevel: startCombinationLevel + index,
        selectedColumns: mappingSelectedColumnsProperty(backSourceConfig.source.columns),
        preparations: mappingSourcePreparationToFront(backSourceConfig.source.preparations),
        retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
        deduplicationSettings: {
          deduplicationKeys: backSourceConfig.source.deduplicationSettings.key.columnIds,
        },
        condition: mappingJoinConditionProperty(backSourceConfig.condition),
        selectedEntity: mapSelectedEntity(backSourceConfig.source.sourceSettings),
      };

      joinedSourcesConfiguration.push(sourceConfiguration);
    } else if (backSourceConfig.source.type === DataSourceType.audience) {
      // Prepare dataSource and source configuration for audience as source types
      const dataSource = mapBackendAudienceDatasourceToActiveDataSource(backSourceConfig.source);
      const sourceConfiguration: JoinActiveSourceConfiguration = {
        dataSource,
        dataType: dataTypesEnum.JOIN,
        key: backSourceConfig.source.audienceId,
        combinationLevel: startCombinationLevel + index,
        selectedColumns: [],
        preparations: [],
        retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
        condition: mappingJoinConditionProperty(backSourceConfig.condition),
        // @@@Todo: we should get audience as source primary keys
        deduplicationSettings: {
          deduplicationKeys: [],
        },
        selectedEntity: mapSelectedEntityOfAudienceAsSource(backSourceConfig.source),
      };

      joinedSourcesConfiguration.push(sourceConfiguration);
    } else if (backSourceConfig.source.type === DataSourceType.sync) {
      const dataSource = mapBackendSyncDatasourceToActiveDataSource(backSourceConfig.source);
      const sourceConfiguration: JoinActiveSourceConfiguration = {
        dataSource,
        dataType: dataTypesEnum.JOIN,
        key: backSourceConfig.source.syncId,
        combinationLevel: startCombinationLevel + index,
        selectedColumns: [],
        // TODO @@@@koralex [should it be defined for sync sources?]
        retrievalMode: retrievalModeEnum.INCREMENTAL_MODE,
        // TODO @@@@koralex [master id?]
        deduplicationSettings: {
          deduplicationKeys: [
            backSourceConfig.source.masterIdColumnId,
          ],
        },
        condition: mappingJoinConditionProperty(backSourceConfig.condition),
        preparations: [],
        selectedEntity: mapSelectedEntityOfSyncAsSource(backSourceConfig.source),
      };
      joinedSourcesConfiguration.push(sourceConfiguration);
    }
  });
  return joinedSourcesConfiguration;
};

export const mappingSourcePreparationToFront = (
  frontPreparations: BackSourcePreparation[]
): SourcePreparation[] => {
  const backSourcePreparations: SourcePreparation[] = frontPreparations.map((preparation) => {
    if (preparation.type === PreparationActionType.custom_function) {
      return {
        type: PreparationActionType.custom_function,
        function: {
          name: preparation.function.name,
          expectedType: mappingColumnTypePropertyToFront(preparation.function.expectedType),
        },
        column: preparation.column,
      };
    }
    return preparation;
  });

  return backSourcePreparations;
};
