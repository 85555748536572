import { AudienceColumn } from '@features/audiences/types/AudienceColumn';
import { ConnectorType } from '@features/connections/types';
import {
  IBackApiDestinationV2,
  IBackDestination,
} from '@features/syncs/types/backTypes/IBackDestinationSettings';
import {
  DestinationReplacementMode,
  ISFTPDestinationSettings,
  SftpDestinationColumn,
  SftpDestinationDataSettingsEnum,
} from '@features/syncs/types/IDestinationSettings';
import { generateId } from '@utils/helpers';
import {
  DestinationEnum,
  IApiDestinationV2,
  IDatabaseDestinationV2,
  IDestination,
  IFileSystemDestinationV2,
} from '../types/Destination';

export const isBackApiDestination = (
  destination: IBackDestination
): destination is IBackApiDestinationV2 => {
  return destination.type === DestinationEnum.api;
};
export const frontApiDestination = (destination: IDestination | undefined) => {
  return destination
    ? isFrontApiDestinationType(destination)
      ? destination
      : undefined
    : undefined;
};
export const frontDBDestination = (destination: IDestination | undefined) => {
  return destination
    ? isFrontDBDestinationType(destination)
      ? destination
      : undefined
    : undefined;
};

export const frontFileSystemDestination = (destination: IDestination | undefined) => {
  return destination
    ? isFrontFileSystemDestinationType(destination)
      ? destination
      : undefined
    : undefined;
};

export const isFrontApiDestinationType = (
  destination: IDestination
): destination is IApiDestinationV2 => destination.type === DestinationEnum.api;

export const isFrontDBDestinationType = (
  destination: IDestination
): destination is IDatabaseDestinationV2 => destination.type === DestinationEnum.db;

export const isFrontFileSystemDestinationType = (
  destination: IDestination
): destination is IFileSystemDestinationV2 => destination.type === DestinationEnum.fs;

export const getInitialDestination = (
  connectionId: string,
  type: ConnectorType
): IDestination | undefined => {
  switch (type) {
    case ConnectorType.sftp:
      return {
        type: DestinationEnum.fs,
        connectionId,
        settings: {
          type: ConnectorType.sftp,
          columns: [],
          directoryPath: '',
          fileName: '',
          fileNameExtension: '.csv',
          dataSettings: {
            type: SftpDestinationDataSettingsEnum.csv,
            delimiter: ',',
            escape: '"',
            quote: '"',
            bom: false,
          },
        },
      };
    case ConnectorType.postgres:
      return {
        type: DestinationEnum.db,
        connectionId,
        settings: {
          type: ConnectorType.postgres,
          replacementMode: DestinationReplacementMode.delete_records,
          schemaName: '',
          tableName: '',
          columns: [],
          key: [],
        },
      };
    case ConnectorType.mysql:
      return {
        type: DestinationEnum.db,
        connectionId,
        settings: {
          type: ConnectorType.mysql,
          replacementMode: DestinationReplacementMode.delete_records,
          schemaName: '',
          tableName: '',
          columns: [],
          key: [],
        },
      };
    case ConnectorType.bigQuery:
      return {
        type: DestinationEnum.db,
        connectionId,
        settings: {
          type: ConnectorType.bigQuery,
          replacementMode: DestinationReplacementMode.delete_records,
          schemaName: '',
          tableName: '',
          location: '',
          columns: [],
          key: [],
        },
      };
    case ConnectorType.shopify:
    case ConnectorType.sendinblue:
    case ConnectorType.sendinblueTracker:
    case ConnectorType.brevo:
    case ConnectorType.facebookAds:
    case ConnectorType.splio:
    case ConnectorType.mailchimp:
    case ConnectorType.braze:
      return {
        type: DestinationEnum.api,
        connectionId,
        settings: {
          entitySettings: undefined,
          keyFieldId: undefined,
          entityTypeId: '',
        },
      };
    case ConnectorType.selligent:
      return {
        type: DestinationEnum.api,
        connectionId,
        settings: {
          destinationSettings: {},
          entitySettings: undefined,
          keyFieldId: undefined,
          entityTypeId: '',
        },
      };
    default:
      throw new Error(`caseNever: ${type}`);
  }
};

export const prepareInitialSftpDestinationColumns = (
  audienceColumns: AudienceColumn[]
): SftpDestinationColumn[] =>
  audienceColumns.map((c) => ({
    key: generateId(),
    name: c.name,
    externalName: c.name,
    sync: false,
  }));

export const addUnSyncedAudienceColumnsToSftpColumns = (
  audienceColumns: AudienceColumn[],
  activeSftpDestinationSettings?: ISFTPDestinationSettings
): SftpDestinationColumn[] =>
  audienceColumns.reduce((result: SftpDestinationColumn[], elem) => {
    const syncedColumn = activeSftpDestinationSettings?.columns.find((x) => x.name === elem.name);
    if (syncedColumn) {
      result.push(syncedColumn);
    } else {
      result.push({
        key: generateId(),
        name: elem.name,
        externalName: elem.name,
        sync: false,
      });
    }
    return result;
  }, []);
