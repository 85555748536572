import {
  emptyMappedColumnValues,
  emptyMappedUnionColumnValues,
} from '@features/audiences/helpers/mappingSourcesHelpers/constants';
import {
  ActiveSourceConfiguration,
  MappedColumn,
  MappedColumns,
  MappingRow,
  mappingRowTypesEnum,
} from '@features/audiences/types';
import {
  JoinedAudienceSource,
  JoinedAudienceSourceColumnConditionType,
  JoinedAudienceSourceConditionType,
  RegularJoinedAudienceSourceConditionColumn,
  SourceToAudienceColumnMapping,
  UnionedAudienceSource,
} from '../../../audienceBackTypes/audienceSpecification';
import { DataSourceType } from '../../../audienceBackTypes/source';
import { mappingColumnTypePropertyToFront } from '../mappingColumnTypePropertyToFront';
import { MASTER_ID_COLUMN_ID_FOR_JOIN } from '@features/audiences/helpers/constants/joinSourceMapping';
import { columnTypesEnum } from '@features/objects/types';
import { MASTER_ID_COLUMN_NAME } from '@features/audiences/helpers/constants/constants';

export const mappingSourcesMappedColumns = (
  sources: {
    unioned: UnionedAudienceSource[];
    joined: JoinedAudienceSource[];
  },
  sourcesConfigurationList: ActiveSourceConfiguration[],
  audienceMapping: MappingRow[]
): MappingRow[] => {
  if (!audienceMapping.some(({ rowType }) => rowType === mappingRowTypesEnum.MASTER_ID_ROW)) {
    audienceMapping.push({
      audienceColumnName: MASTER_ID_COLUMN_NAME,
      audienceColumnType: columnTypesEnum.SYSTEM_ID,
      key: MASTER_ID_COLUMN_ID_FOR_JOIN,
      mappedColumns: {},
      rowType: mappingRowTypesEnum.MASTER_ID_ROW,
    });
  }

  return audienceMapping.map((row) => {
    let mappedColumns: MappedColumns = mappingUnionedColumns(
      sources.unioned,
      row.key,
      sourcesConfigurationList
    );

    mappedColumns = mappingJoinedColumns(
      mappedColumns,
      sources.joined,
      row.key,
      sourcesConfigurationList
    );

    return { ...row, mappedColumns };
  });
};

const mappingUnionedColumns = (
  unionedSources: UnionedAudienceSource[],
  mappingRowKey: string,
  sourcesConfigurationList: ActiveSourceConfiguration[]
): MappedColumns => {
  return unionedSources.reduce((result: MappedColumns, sourceConfig) => {
    const { columnsMapping, source } = sourceConfig;
    const mappingSourceDetails: SourceToAudienceColumnMapping | undefined = columnsMapping.find(
      (x) => x.audienceColumnId === mappingRowKey
    );

    if (source.type === DataSourceType.connection) {
      const sourceConfigKey: string = source.id;

      const sourceColumnDetails = source.columns.find(
        (sourceColumn) => sourceColumn.id === mappingSourceDetails?.sourceColumnId
      );
      const mappedColumn: MappedColumn = sourceColumnDetails
        ? {
            columnId: sourceColumnDetails.id,
            columnName: sourceColumnDetails.name,
            columnType: mappingColumnTypePropertyToFront(
              sourceColumnDetails.castTo || sourceColumnDetails.type
            ),
          }
        : emptyMappedUnionColumnValues;
      return { ...result, [sourceConfigKey]: mappedColumn };
    }
    if (source.type === DataSourceType.audience) {
      const sourceConfigKey: string = source.audienceId;
      const sourceConfigFrontType = sourcesConfigurationList.find((x) => x.key === sourceConfigKey);
      const sourceColumnDetails = sourceConfigFrontType?.selectedColumns.find(
        (sourceColumn) => sourceColumn.id === mappingSourceDetails?.sourceColumnId
      );
      const mappedColumn: MappedColumn = sourceColumnDetails
        ? {
            columnId: sourceColumnDetails.id,
            columnName: sourceColumnDetails.name,
            columnType: sourceColumnDetails.type,
          }
        : emptyMappedUnionColumnValues;
      return { ...result, [sourceConfigKey]: mappedColumn };
    }

    if (source.type === DataSourceType.sync) {
      const sourceConfigKey: string = source.syncId;
      const sourceConfigFrontType = sourcesConfigurationList.find((x) => x.key === sourceConfigKey);
      const sourceColumnDetails = sourceConfigFrontType?.selectedColumns.find(
        (sourceColumn) => sourceColumn.id === mappingSourceDetails?.sourceColumnId
      );
      const mappedColumn: MappedColumn = sourceColumnDetails
        ? {
          columnId: sourceColumnDetails.id,
          columnName: sourceColumnDetails.name,
          columnType: sourceColumnDetails.type,
        }
        : mappingSourceDetails?.sourceColumnId ? {
          columnId: mappingSourceDetails?.sourceColumnId,
          columnName: '',
        } : emptyMappedUnionColumnValues;
      return { ...result, [sourceConfigKey]: mappedColumn };
    }

    return result;
  }, {});
};
const mappingJoinedColumns = (
  mappedColumns: MappedColumns,
  joinedSources: JoinedAudienceSource[],
  mappingRowKey: string,
  sourcesConfigurationList: ActiveSourceConfiguration[]
): MappedColumns => {
  const joinMappedColumns: MappedColumns = joinedSources.reduce(
    (result: MappedColumns, sourceConfig) => {
      const { condition, source } = sourceConfig;
      const mappingSourceDetails: RegularJoinedAudienceSourceConditionColumn | undefined =
        condition.type === JoinedAudienceSourceConditionType.regular
          ? condition.columns.find(
              (x) =>
                (x.type === JoinedAudienceSourceColumnConditionType.data &&
                  x.audienceColumnId === mappingRowKey) ||
                (x.type === JoinedAudienceSourceColumnConditionType.master_id &&
                  mappingRowKey === MASTER_ID_COLUMN_ID_FOR_JOIN)
            )
          : undefined;
      if (source.type === DataSourceType.connection) {
        const sourceConfigKey: string = source.id;

        const sourceColumnDetails = source.columns.find(
          (sourceColumn) => sourceColumn.id === mappingSourceDetails?.sourceColumnId
        );
        const mappedColumn: MappedColumn = sourceColumnDetails
          ? {
              columnId: sourceColumnDetails.id,
              columnName: sourceColumnDetails.name,
              columnType: mappingColumnTypePropertyToFront(
                sourceColumnDetails.castTo || sourceColumnDetails.type
              ),
            }
          : emptyMappedColumnValues;
        return { ...result, [sourceConfigKey]: mappedColumn };
      }
      if (source.type === DataSourceType.audience) {
        const sourceConfigKey: string = source.audienceId;
        const sourceConfigFrontType = sourcesConfigurationList.find(
          (x) => x.key === sourceConfigKey
        );
        const sourceColumnDetails = sourceConfigFrontType?.selectedColumns.find(
          (sourceColumn) => sourceColumn.id === mappingSourceDetails?.sourceColumnId
        );
        const mappedColumn: MappedColumn = sourceColumnDetails
          ? {
              columnId: sourceColumnDetails.id,
              columnName: sourceColumnDetails.name,
              columnType: sourceColumnDetails.type,
            }
          : emptyMappedColumnValues;
        return { ...result, [sourceConfigKey]: mappedColumn };
      }
      if (source.type === DataSourceType.sync) {
        const sourceConfigKey: string = source.syncId;
        const sourceConfigFrontType = sourcesConfigurationList.find(
          (x) => x.key === sourceConfigKey
        );
        const sourceColumnDetails = sourceConfigFrontType?.selectedColumns.find(
          (sourceColumn) => sourceColumn.id === mappingSourceDetails?.sourceColumnId
        );
        const mappedColumn: MappedColumn = sourceColumnDetails
          ? {
              columnId: sourceColumnDetails.id,
              columnName: sourceColumnDetails.name,
              columnType: sourceColumnDetails.type,
            } : mappingSourceDetails?.sourceColumnId ? {
              columnId: mappingSourceDetails?.sourceColumnId,
              columnName: ''
            } :
           emptyMappedColumnValues;
        return { ...result, [sourceConfigKey]: mappedColumn };
      }

      return result;
    },
    {}
  );
  return { ...mappedColumns, ...joinMappedColumns };
};
