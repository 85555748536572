import { IColumnType } from '@features/audiences/ducks/api/audienceBackTypes/columnType';

export type GetDestinationSchemaConnectionNotFound = {
  __typename: GetDestinationSchemaTypename.GetDestinationSchemaConnectionNotFound;
  id: string;
};

export enum DestinationType {
  api = 'api',
  fs = 'fs',
  db = 'db',
}

export type GetDestinationSchemaConnectionDestinationTypeIsNotSupported = {
  __typename: GetDestinationSchemaTypename.GetDestinationSchemaConnectionDestinationTypeIsNotSupported;
  connectionId: string;
  destinationType: DestinationType;
};

// export enum DestinationResponseType {
//   output = 'output',
//   connectinoNotFound = 'connectinoNotFound',
//   connectionNotSupported = 'connectionNotSupported',
// }
export type DatabaseColumnDescriptor = {
  name: string;
  supportedTypes: IColumnType[];
};

export type DatabaseDestinationSchema = {
  __typename: GetDestinationSchemaTypename.DatabaseDestinationSchema;
  isTableFound: boolean;
  columnDescriptors: DatabaseColumnDescriptor[];
};

export enum GetDestinationSchemaTypename {
  DatabaseDestinationSchema = 'DatabaseDestinationSchema',
  GetDestinationSchemaConnectionNotFound = 'GetDestinationSchemaConnectionNotFound',
  GetDestinationSchemaConnectionDestinationTypeIsNotSupported = 'GetDestinationSchemaConnectionDestinationTypeIsNotSupported',
}

export type GetDestinationSchemaOutput =
  | DatabaseDestinationSchema
  | GetDestinationSchemaConnectionNotFound
  | GetDestinationSchemaConnectionDestinationTypeIsNotSupported;
