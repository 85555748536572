import { mapLatestExecutionFromBackType } from '@features/audiences/ducks/api/mappingAudienceTypes/toFrontType/toFrontType';
import {
  FieldMapItemType,
  Mapping,
  RecordFieldSyncStrategy,
  Sync,
  SyncRecordsEachTimeStrategy,
  SyncSchedule,
  SyncScheduleTypes,
} from '@features/syncs/types';
import { IBackDestination } from '@features/syncs/types/backTypes/IBackDestinationSettings';
import { IFieldMapItem } from '@features/syncs/types/backTypes/IFieldMapItem';
import {
  DestinationTriggerType,
  ISyncExecutionTriggerSpecification,
} from '@features/syncs/types/backTypes/ISyncExecutionTriggerSpecification';
import { SyncReadModel } from '@features/syncs/types/backTypes/SyncModel';
import { generateId, getExtensionFromString, removeExtensionFromString } from '@utils/helpers';
import { isBackApiDestination } from '@features/syncs/helpers/DestinationSettingsHelpers';
import { caseNever } from '@utils/case-never';
import { DestinationEnum, IDestination } from '../../../types/Destination';
import { ConnectorType } from '@features/connections/types';
import { mappingSegmentationToFront } from './mappingSegmentationToFront';
import { AudienceColumn } from '@features/audiences/types/AudienceColumn';
import { ISyncRecordsScope, SyncRecordsScope } from '../../../types/backTypes/ISyncRecordsScope';
import {
  IRecordsScopeDateFilter,
  IRecordsScopeFilter,
  RecordsScopeFilter,
  RecordsScopeDateFilter,
} from '../../../types/backTypes/ISyncRecordsScopeFilter';
import dayjs from 'dayjs';

export const syncToFront = (backSync: SyncReadModel, audienceColumns?: AudienceColumn[]): Sync => {
  const destination = backSync.specification.destination;
  const isApiDestination = isBackApiDestination(destination);
  const frontSync: Sync = {
    key: backSync.id,
    name: backSync.name,
    audienceId: backSync.specification.audienceId,
    destinationConnectionId: destination.connectionId,
    syncSegmentation:
      backSync.specification.segmentation && audienceColumns
        ? mappingSegmentationToFront(audienceColumns || [], backSync.specification.segmentation)
        : undefined,
    schedule: mappingScheduleProperty(backSync.specification.executionTrigger),
    destination: mappingDestinationProperty(destination),
    syncEachTimeStrategy: isApiDestination
      ? getSyncEachTimeStrategy(destination.settings.fieldMap)
      : SyncRecordsEachTimeStrategy.eachTimeIsUpdated,
    mapping: isApiDestination ? transformFieldMapsToMappings(destination.settings.fieldMap) : [],
    active: backSync.isEnabled,
    createdAt: backSync.createdAt,
    updatedAt: backSync.updatedAt,
    version: backSync.version,
    deployment: backSync.deployment,
    latestExecution: mapLatestExecutionFromBackType(
      backSync.lastExecution ? [backSync.lastExecution] : undefined
    ),
  };
  return frontSync;
};
const mappingDestinationProperty = (destination: IBackDestination): IDestination => {
  switch (destination.type) {
    case DestinationEnum.api:
      const { fieldMap, ...settings } = destination.settings;
      return {
        ...destination,
        settings,
      };
    case DestinationEnum.fs:
      switch (destination.settings.type) {
        case ConnectorType.sftp:
          return {
            ...destination,
            settings: {
              ...destination.settings,
              fileName: removeExtensionFromString(destination.settings.fileName),
              fileNameExtension: getExtensionFromString(destination.settings.fileName),
              columns: destination.settings.columns.map((elem) => {
                return {
                  key: generateId(),
                  name: elem.name,
                  externalName: elem.externalName || elem.name,
                  sync: true,
                };
              }),
            },
          };
        default:
          throw new Error('Unknown FS destination type');
      }

    case DestinationEnum.db:
      return destination;
    default:
      return caseNever(destination);
  }
};

const mapSyncRecordsScope = (recordsScope: ISyncRecordsScope) => {
  switch (recordsScope.type) {
    case SyncRecordsScope.allRecords:
      return {
        recordsScope: SyncRecordsScope.allRecords,
      };
    case SyncRecordsScope.unprocessedRecords: {
      return {
        recordsScope: SyncRecordsScope.unprocessedRecords,
      };
    }
    case SyncRecordsScope.recordsInTimePeriod: {
      return {
        recordsScope: SyncRecordsScope.recordsInTimePeriod,
        ...mapSyncRecordsScopeFilter(recordsScope.filter),
      };
    }
    default:
      return caseNever(recordsScope);
  }
};

const mapSyncRecordsScopeFilter = (recordsScopeFilter: IRecordsScopeFilter) => {
  // TODO @@@@koralex [add support for createdAt/updatedAt/createdOrUpdatedAt]
  switch (recordsScopeFilter.type) {
    case RecordsScopeFilter.createdAt:
      return {
        dateFilterType: RecordsScopeFilter.createdAt,
        ...mapSyncRecordsScopeDateFilter(recordsScopeFilter.createdAt),
      };
    case RecordsScopeFilter.updatedAt:
      return {
        dateFilterType: RecordsScopeFilter.updatedAt,
        ...mapSyncRecordsScopeDateFilter(recordsScopeFilter.updatedAt),
      };
    case RecordsScopeFilter.createdOrUpdatedAt:
      return {
        dateFilterType: RecordsScopeFilter.createdOrUpdatedAt,
        ...mapSyncRecordsScopeDateFilter(
          recordsScopeFilter.createdAt || recordsScopeFilter.updatedAt
        ),
      };
    default:
      return caseNever(recordsScopeFilter);
  }
};

const mapSyncRecordsScopeDateFilter = (recordsScopeDateFilter: IRecordsScopeDateFilter) => {
  switch (recordsScopeDateFilter.type) {
    case RecordsScopeDateFilter.after:
      return {
        fromMoment: dayjs(recordsScopeDateFilter.after),
      };
    case RecordsScopeDateFilter.before:
      return {
        toMoment: dayjs(recordsScopeDateFilter.before),
      };
    case RecordsScopeDateFilter.range:
      return {
        fromMoment: dayjs(recordsScopeDateFilter.after),
        toMoment: dayjs(recordsScopeDateFilter.before),
      };
    default:
      return caseNever(recordsScopeDateFilter);
  }
};

const mappingScheduleProperty = (
  executionTrigger: ISyncExecutionTriggerSpecification
): SyncSchedule => {
  switch (executionTrigger.type) {
    case DestinationTriggerType.manual:
      return { type: SyncScheduleTypes.MANUAL };

    case DestinationTriggerType.realTime:
      return { type: SyncScheduleTypes.REAL_TIME };

    case DestinationTriggerType.scheduled:
      return {
        type: SyncScheduleTypes.SCHEDULED,
        cron: executionTrigger.schedule.value,
        ...mapSyncRecordsScope(executionTrigger.recordsScope),
      };
    default:
      throw Error('Execution trigger type not found');
  }
};

const transformFieldMapsToMappings = (fieldMaps?: IFieldMapItem[]): Mapping[] => {
  return (
    fieldMaps?.reduce((result: Mapping[], elem) => {
      if (elem.type === FieldMapItemType.audience_field) {
        result.push({
          id: generateId(),
          type: elem.type,
          audienceColumnId: elem.audienceColumnId,
          audienceColumnName: elem.audienceColumnName,
          destinationFieldId: elem.destinationFieldId,
          syncStrategy: elem.syncStrategy,
        });
      }
      if (elem.type === FieldMapItemType.static_value) {
        result.push({
          id: generateId(),
          type: elem.type,
          staticValue: elem.staticValue,
          destinationFieldId: elem.destinationFieldId,
          syncStrategy: elem.syncStrategy,
        });
      }
      return result;
    }, []) || []
  );
};

const getSyncEachTimeStrategy = (fieldMaps?: IFieldMapItem[]): SyncRecordsEachTimeStrategy => {
  const isFieldStrategyCreatedOrUpdated = (element: IFieldMapItem) =>
    element.syncStrategy === RecordFieldSyncStrategy.CreatedOrUpdated;
  return fieldMaps?.some(isFieldStrategyCreatedOrUpdated)
    ? SyncRecordsEachTimeStrategy.eachTimeIsUpdated
    : SyncRecordsEachTimeStrategy.onlyOnceAtCreation;
};
